import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import {
  ButtonWrapper,
  MainText,
  StyledModal,
  FormGroupStyled,
  StyledForm,
  StyledError,
} from './styles';
import { Modal } from '@mui/material';
import { PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useLoginAsUser } from 'features/feature-users-managment/hooks/useLoginAsUser';
import { loginAsUser } from 'application/store/reducers/Professionals/ActionCreators';
import { UserLoginResDto, UserType } from '@docbay/schemas';
import { setLoginAsUserError } from 'application/store/reducers/Professionals/ProfessionalsSlice';

const docbayProURL = process.env.REACT_APP_DOCBAYPRO_URL;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  entityUserType: UserType;
}

const LoginAsUserModal: FC<Props> = ({
  isOpen,
  onClose,
  userId,
  entityUserType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { loginAsUserError } = useAppSelector((state) => state.professionals);

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    getValues,
  } = useLoginAsUser({
    emailError: t('errors.emailFormat'),
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    if (loginAsUserError) {
      setError('email', { message: ' ' });
      setError('password', { message: ' ' });
    } else {
      clearErrors();
    }
  }, [loginAsUserError]);

  useEffect(() => {
    if (loginAsUserError) {
      dispatch(setLoginAsUserError(t('errors.required')));
    }
  }, [watch('email'), watch('password')]);

  const onSubmit = () => {
    const params = {
      email: watch('email'),
      password: watch('password'),
      entityUserType,
      entityId: userId,
    };

    dispatch(loginAsUser(params)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        const userData = data.payload as UserLoginResDto;

        const params = window.btoa(encodeURI(JSON.stringify(userData.user)));

        window.open(
          `${docbayProURL}/login-as/${getValues('email')}/${userData.token}/${
            userData.refreshToken
          }/${userData.expiresAt}/${params}`,
          '_blank',
          'noopener, noreferrer',
        );

        onClose();
      }
    });
  };

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <MainText>{t('users_managment.login_as_user_modal.title')}</MainText>
          <p>{t('users_managment.login_as_user_modal.enter_your_data')}</p>
          {!!loginAsUserError.length && (
            <StyledError variant="body1">
              {t('auth.log_in.incorrect_credentials')}
            </StyledError>
          )}
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <FormGroupStyled>
              <Input
                id="email"
                label={t('auth.email') || ''}
                type="email"
                placeholder={t('auth.log_in.email_placeholder') || ''}
                register={register}
                errorMessage={errors.email?.message}
              />
              <Input
                id="password"
                label={t('auth.log_in.password_label') || ''}
                type="password"
                placeholder={t('auth.log_in.password_label') || ''}
                errorMessage={errors.password?.message}
                register={register}
              />
            </FormGroupStyled>
            <ButtonWrapper>
              <SecondaryButton onClick={onClose}>
                {t('global.cancel')}
              </SecondaryButton>
              <PrimaryButton type="submit">
                {t('auth.log_in.log_in_button')}
              </PrimaryButton>
            </ButtonWrapper>
          </StyledForm>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default LoginAsUserModal;
